.product-face-description {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 60px;
  padding-bottom: 50px;
  gap: 50px;
  align-items: center;
}

.product-face-description > div > p, .size-bigger-font {
  font-size: 1.3rem !important;
  line-height: 1.8 !important;
}

.bottom-border-red {
  border-bottom: 2px solid var(--dred);
}

.product-flex {
  display: flex;
  justify-content: space-between;
  gap: 90px;
  width: 100%;
  padding: 30px 0;
}

.product-flex > *{
  width: 50%;
}

.item-video-container { 
  width: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.product-flex > .item-video-container > video {
  position: absolute;
}

.rm-padding-right {
  padding-right: 0;
}

.spects {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  padding: 70px 0;
  justify-content: center;
}

.spects > h2 {
  margin-bottom: 30px;
}

.spects > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 80%;
  margin-top: 40px;
}

.spects > ul > li {
  display: grid;
  grid-template-columns: .9fr .9fr 1.3fr;
  padding-bottom: 15px;
}

.spects > ul > li > span:nth-child(3), .spects > ul > li > span:nth-child(2) {
  font-weight: 300 !important;
  line-height: 1.8;
}

.sepc-bg-img {
  background-image: url(https://en.orionstar.com/static/demo/img/zcbcs_bg.jpg);
}

.border-top-with-padding {
  border-top: 1px solid #ddd;
  padding-top: 60px;
}