.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  gap: 10px;
  align-items: center;
}

#loading {
  display: inline-block;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dred);
  height: 60px;
  border: 3px solid rgba(55, 55, 55, 0.3);
  border-radius: 50%;
  border-top-color: var(--dred);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}