
.side-bar-menu {
  height: 100vh;
  display: flex;
  z-index: 100;
  background-color: #fff;
  justify-content: space-between;
  flex-direction: column;
  max-width: 265px;
  width: 100%;
  border-right: 1px solid #e9e9ef;
  position: fixed;
  overflow: hidden;
  font-size: 14px;
}

.contain-col {
  width: 100%;
  margin-top: 20px;
}

.contain-col > span {
  color: #545a6d;
    cursor: default;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 20px!important;
    pointer-events: none;
}

.nav-dash {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.nav-dash > li {
  display: flex;
  gap: 5px;
  transition: .3s;
  padding: 10px 15px;
  align-items: center;
  cursor: pointer;
}

.nav-dash > li > a {
  color: #525252;
}

.nav-dash > li:hover {
  background-color: #e9e9ef;
}

.nav-dash > li > i { 
  
  color: #525252;
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
}

ul {
  margin-bottom: 0 !important;
}

.logo-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.logo-welcome {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e9e9ef;
  padding: 15px 20px;
  color: rgb(114, 114, 114);
}

.logout-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #e9e9ef;
  gap: 25px 20px;
  font-size: 14px;
}

.logout-user-info h3 {
  font-size: 20px;
}


.logout-user-info > span {
  font-size: 12px;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.logout-user-info > button {
  border: 1px solid #e9e9ef;
  background-color: transparent;
  padding: 10px;
  font-size: 1.2rem;
}

.right-side-content {
  padding-left: 265px;
  width: 100%;
}

.header-content-right-page {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-sizing-db {
  max-width: 1400px;
  width: 100%;
}

.wrapper-db-content {
  display: flex;
  flex-direction: column;
}

.header-and-create-button {
  display: flex;
  justify-content: space-between;
}

.header-and-create-button > h3 {
  font-size: 2rem;
}



.activity-table {
  width: 100%;
}

.activity-table > thead {
  display: flex;
  justify-content: space-between;
}

.activity-table > tbody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.activity-table > tbody > tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(212, 212, 212);
}

.activity-table > thead > th{
  font-weight: 400;
  padding: 20px;
  padding-top: 0;
  width: 20%;
  color: rgb(66, 66, 66);
}

.activity-table > thead > th:last-child{
  text-align: right;
}

.activity-table > tbody > tr,  .activity-table > thead{
  display: grid;
  grid-template-columns: 2fr .8fr .8fr  1.3fr;
  width: 100%;
}

.activity-table > tbody > tr > td, .activity-table > thead > th {
  width: 100%;
}



.activity-table > tbody > tr > td  {
  font-size: .95rem;
  flex-direction: column;
  font-weight: 300;
  padding: 20px;
  color: rgb(141, 141, 141);
  display: flex;
  gap: 10px;
}

.activity-table > tbody > tr > td > span {
  font-weight: 500;
  text-transform: capitalize;
  font-size: .9rem;
}

.activity-table > tbody > tr > td > a {
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  transition: .3s;
  gap: 10px;
}

.activity-table > tbody > tr > td:last-child {
  justify-content: flex-end;
  color: #007bff;
  flex-direction: row !important;
}

.edit-button{
  background-color: #007bff;
  color: #fff;
}

.edit-button:hover {
  background-color: #0463c9;
  color: #fff;
}

.new-item {
  font-size: .8rem;
  color: #fff;
  padding: 8px 10px;
}

.old-item {
  border: 1px solid #e9e9ef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: .8rem;
}

.inquire-table > tbody > tr,  .inquire-table > thead {
  grid-template-columns: .9fr 2fr 2fr 1.5fr 2fr;
}

.listing-table > tbody > tr,  .listing-table > thead {
  grid-template-columns: .5fr 1.5fr .5fr .8fr .5fr 1.3fr;
}

.bill-table > tbody > tr,  .bill-table > thead {
  grid-template-columns: .5fr 1.4fr .5fr .5fr 2fr
}

.information-tab {
  background-color: #0f0f41;
  color: #fff;
}

.single-info-title {
  gap: 5px;
  padding: 20px 25px;
}

.single-info-title > h4 {
  font-weight: 300;
}

.information-tab-content {
  padding-left: 220px;
}


.logo {
  position: absolute;
  padding: 20px;
  left: 0;
  top: -60px;
  background-color: #fff;  
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.slider-information {
  border: 1px solid;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(https://images.unsplash.com/photo-1592838064575-70ed626d3a0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2836&q=80);
}

.slider-information-wrapper {
  background-color: #0f0f4174;
  width: 100%;
  height: 80vh;
}

.white-bg {
  background-color: #fff;
  color: #0f0f41;
}

.white-bg > h4 {
  font-weight: 500;
}

.slider-information-content > h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.slider-information-content > h1 { 
  color: #fff;
  font-size: 4rem;
}

.slider-information-content > p {
  text-align: center;
  color: #fff;
  line-height: 25px;
  padding: 20px;
  font-size: 1.1rem;
  max-width: 800px;
}

.about-wrapper {
  padding: 80px 0;
  padding-bottom: 80px
}

.about-us-content {
  width: 55%;
}

.about-us-content > h4 {
  color: #b22f33;
  font-weight: 300;
  font-size: 1.3rem;
}

.about-us-content > h2 {
  font-size: 3rem;
  line-height: 45px;
  padding: 20px 0;
}

.about-us-content > p, .text-about > p {
  color: #6B7280;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
  line-height: 1.7;
}

.images-selection {
  position: relative;
  padding: 80px 0;
}

.about-images {
  border-radius: 10px;
  margin-left: 250px;
  border: 5px solid #fff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.floating-img {
  border-radius: 8px;
  bottom: 0;
  left: 0;
  border: 5px solid #fff;
  position: absolute;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.floating-img-again {
  top: 0;
  left: 50px;
  z-index: -100;
  border: 5px solid #fff;
  position: absolute;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.ghost-boxes {
  gap: 20px;
  padding: 20px 0;
}

.ghost-box {
  border: 2px solid #b22f33;
  border-radius: 3px;
  padding: 20px;
  gap: 10px;
  color: #b22f33;
}

.ghost-box > h1 {
  font-size: 2rem;
}

.services-container, .logo-companies {
  background-color: #F3F4F8;
}

.list-of-services {
  padding: 30px 0;
  padding-top: 10px;
  max-width: 70%;
}

.list-of-services > li {
  display: flex;
  width: 50%;
  text-align: left;
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
}

.list-of-services > li > i {
  color: #b22f33;
  font-size: 2rem;
}

.selection-header-text {
  font-size: 2rem;
  line-height: 50px;
}

.view-truck-button {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;
  transition: .3s;
  color: #0f0f41;
  text-decoration: none;
}

.view-truck-button:hover {
  padding-right: 30px;
  color: #b22f33 !important;
}

.year-max > input {
  width: 45%;
}

.filter-inputs-fields {
  border: none !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
  margin-bottom: 20px;
  padding: 14px 0;
  color: #1d1d23;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  background-color: transparent;
  border-left: none;
  border-right: none;
  font-weight: 600;
  border-top: none;
  border-bottom: 1.5px solid rgba(0,0,0,.2);
  -webkit-transition: padding .2s ease-in-out,background .2s ease-in-out;
  transition: padding .2s ease-in-out,background .2s ease-in-out;
  min-height: 48px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  outline: none;
}

.filter-inputs-fields:focus {
  border: none;
  box-shadow: none;
}

.tags-nav {
  clear: both;
  overflow: hidden;
  padding: 0 5px;
}

.tags-nav li {
  float: left;
  width: 100%;
  overflow: hidden;
}

.tags-nav li .md-checkbox {
  height: auto;
  margin: 0 0 10px;
}

.md-checkbox {
  position: relative;
  height: 20px;
  margin: 10px 0;
  margin-right: 10px;
  overflow: hidden;
}

.md-checkbox input[type=checkbox], .md-checkbox input[type=radio] {
  outline: 0;
  margin-right: 10px;
  visibility: hidden;
  position: absolute;
}

.md-checkbox input[type=checkbox]:checked+label:after, .md-checkbox input[type=radio]:checked+label:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 5px;
  left: 4px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
}

input[type=checkbox] {
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.tags-nav li .md-checkbox label {
  position: static;
  margin: 0;
  font-size: 14px;
  white-space: inherit;
  line-height: 22px;
}

.md-checkbox label {
  cursor: pointer;
  float: none!important;
  margin-bottom: 0;
  line-height: 0;
  font-size: 14px;
  padding-left: 30px;
  white-space: nowrap;
  font-weight: 400;
}

.md-checkbox input[type=checkbox]:checked+label:before, .md-checkbox input[type=radio]:checked+label:before {
  background: red;
  border: none;
}

.search-button-filter {
  padding: 15px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.reset-filter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  word-wrap: normal;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  gap: 10px;
  font-size: 14px;
}

.reset-filter:hover{
  color: #1d1d23;
}

.detail-page-header {
  background-color: #fff;
  position: relative;
  box-shadow: 0 3px 3px rgb(0 0 0 / 3%);
}

.detail-page-header > div > a {
  padding: 20px 50px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid #fff;
  transition: .3s;
}

.detail-page-header > div > a:hover {
  border-color: #b22f33 !important;
}

.active-single-detail-header-btn {
  border-color: #b22f33 !important;
}

.detail-page {
  background-color: #F3F4F8;
  padding: 25px 0;

}

.detail-page > div {
  align-items: flex-start;
  justify-content: baseline
}

.single-box {
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
  border-radius: 5px;
}

.single-box-header > h2 > i {
  color: #6B7280;
  font-size: 1.2rem;
}

.single-box-header > h2{
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  font-size: 14px;
}

.description > p {
  font-size: 14px;
  font-weight: 300;
  padding: 10px 0;
  text-align: justify;
  line-height: 22px;
}

.techincal-list {
  padding: 20px 0;
  padding-bottom: 0;
}

.techincal-list > li {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 15px;
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.recommened-pro {
  justify-content: flex-start !important;

  padding: 20px 0;
}

.recommened-pro > li {
  width: 32% !important;
}

.recommened-items-single-details {
  background-color: #fff;
}

.gallery-container > img {
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.single-row {
  width: 49%;
}

.about-page-header-content {
  padding: 80px 0;
  padding-bottom: 80px
}

.top-about-box {
  background-color: #F3F4F8;
}

.text-about {
  padding: 20px;
  width: 90%;
}

.text-about > p{
  text-align: center;
}

.contact-col {
  display: flex;
  justify-content: center;
}

.left-col {
  border: 1px solid #e7e7e7;
  max-width: 40%;
  width: 100%;
  padding: 60px 50px;
}

.left-col > span {
  color: #b22f33;
  font-weight: 600;
}

.left-col > h2 {
  line-height: 1.3em;
  font-size: 2rem;
  color: #000000;
  margin: 0px 30px 10px 0px; 
  margin-top: 10px; 
}

.contact-col-info {
  display: flex;
  padding: 80px 0;
  justify-content: space-between;
}

.contact-info-form-col {
  padding: 15px 0;
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.contact-icon {
  border: 1px solid #e7e7e7;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  color: #b22f33;
  border-radius: 50%;
}

.contact-info-form-col > div > h3 {
  font-weight: 500;
}

.contact-info-form-col > div > p {
  color: #686868;
}

.contact-icon > i {
  font-size: 1.3rem;
}

.form-contact {
  width: 100%;
  padding: 50px 80px;
  padding-right: 0;
}

.form-contact > span {
  color: #b22f33;
  font-weight: 600;
}

.form-contact > h2 {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.col-2-filed {
  padding: 10px 0;
  display: flex;
  gap: 10px;
  width: 100%;
}

.col-2-filed > input {
  width: 50%;
  padding: 20px 20px 20px 20px;
  border-radius: 3px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #F6F7F9;
  font-size: 1rem;
}

.form-contact > textarea {
  padding: 20px 20px 20px 20px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  width: 100%;
  margin-bottom: 10px;
  border-width: 0px 0px 0px 0px;
  font-size: 1rem;
  margin-top: 5px;
  background-color: #F6F7F9;
}





.checkboxes {
  padding: 10px 0;
}

/* Checkbox styles */
label.checkbox {
  margin-right: 1rem;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
label.checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
label.checkbox input[type=checkbox]:focus ~ span {
  border: 2px solid #aab0b9;
}
label.checkbox input[type=checkbox]:focus:checked ~ span {
  border: 2px solid #b22f33;
}
label.checkbox input[type=checkbox]:checked ~ span {
  color: #FFFFFF;
  background: #b22f33 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
  border: 2px solid #b22f33;
}
label.checkbox span {
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: -2px;
  width: 1rem;
  height: 1rem;
  background-color: #d4d7dc;
  border: 2px solid #d4d7dc;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icons-verifying {
  font-size: 4rem;
}

.success-color {
  color: green;
}

.social-logo-footer > li{
  padding-left: 10px;
}

.social-logo-footer > li > a {
  color: #ffff;
  cursor: pointer;
}

.inquireForm {
  max-width: 500px;
  padding: 20px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
}

.inquireForm > input {
  padding-left: 10px;
}

.inquireForm > input:focus, textarea:focus {
  border-color: #b22f33 !important;
}

.inquireForm > label {
  margin-bottom: 8px;
  font-size: 14px;
}

.list-of-amenities {
  margin-top: 15px;
}

.list-of-amenities > li {
  width: 33%;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  word-spacing: normal;
  font-size: 14px;
  display: flex;
  gap: 10px;
}

.list-of-amenities > li > i {
  background-color: #c6c6c6;
  font-size: 15px;
  border-radius: 50%;
  padding: 10px;
}

.centering-messages {
  display: flex;
  align-items: center;
  height: 100% !important;
  padding: 20px;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}



.dropzone {
  cursor: pointer;
  border-width: 3px;
  border: 2px dashed #00008B;
  text-align: center !important;
  padding: 3rem !important;
  border-radius: 0.25rem !important;
  min-height: 150px;
  background: white;
  padding: 20px 20px;
}

.form-row{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.blue{
  background-color: #00008B;
}

.blue:hover{
  color: #00008B !important;
  background-color: white;
  border: 1px solid #00008B;
}

.editor{
  height: 500px;
  border: 1px solid #a9a9a9;
}

.dealer-invo h2{
   text-align: right;
  color: #00008B;
}

.dealer-invo span{
  text-align: right;
 color: #9d1217;
}
@media only screen and (max-width: 1250px)  {
  .sec-response{
    overflow-x:scroll;
  }

}

@media only screen and (max-width: 1100px) {
  .flex-row{
    flex-direction:column !important;
    gap: 20px;
  }
  .side-bar-menu li a{
    display: none !important;
  }
  .logo-welcome img{
    width: 80%;
  }
  .side-bar-menu p {
    display: none;
  }
  .side-bar-menu{
    max-width: 100px !important;
  }
  .nav li{
    justify-content: center;
  }
  .contain-col span {
    display: none;
  }
  .logout-user-info span{
    display: none !important;
  }
  .logout-user-info{
    justify-content: center !important;
  }

  .right-side-content{
    padding-left: 101px !important;
  }
}

@media only screen and (max-width: 750px)  {
  .flex-row{
    flex-direction:column !important;
    gap: 20px;
  }
}

.aryan-card{
  width: fit-content;
}



.flex-row{
  display: flex;
  flex-direction:row;
  justify-content:space-evenly;
  text-align: left;
}

.flex-row p {
  color:#b22f33;
  font-size: 18px;
}

.flex-row strong {
  color:#0F0E41;
}

.flex-row a{
  font-size: 18px;
  color: #b22f33;
}

.col.center{
  display: flex;
  justify-content: flex-end !important;
}

.btn-general {
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  gap: 10px;
  justify-content: space-between;
  padding: 15px 30px;
  transition: .3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.nav-dash a:hover {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.primary-btn {
  background-color: #b22f33;
  border: 1px solid #b22f33;
  color: #fff!important;
}

.primary-btn:hover {
  background-color: #791d20;
  border: 1px solid #b22f33;
  color: #fff!important;
}

.footer-copyright-holder p {
  margin-bottom: 0px !important;
}
 a.general-btn, a.general-btn:hover {
  text-decoration: none;
  color: #fff!important;
}

.settings {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.width-editor{
  height: 300px;
}

.error{
  color: rgb(126, 5, 5);
}


.centering-login-box {
  border: 1px solid;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box-container {
  max-width: 450px;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.login-headeer {
  font-size: 1.5rem;
}

.login-headeer-small {
  font-weight: 300;
  font-size: 14px;
  margin-top: 0;
  padding-top: 0;
}

.login-box-container {
  max-width: 450px;
  width: 100%;
}

.login-box-container > form{
  display: flex;
  width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.login-form-box {
  width: 100%;
}