.page-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../public/Images/pics/page-header.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.overlay-dark-filter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.page-header-wrapper {
  color: #fff;
  padding: 60px 0;
}

.page-header-wrapper > h2 {
  font-size: 2.4rem;
  font-weight: 500;
  
}