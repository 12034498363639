.content-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0;
}

.contact-form-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.contact-form-wrapper > img{
  width: 45%;
  height: 700px;
  overflow: hidden;
  object-fit: contain;
  border-radius: 5px;
}

.contact-form {
  width: 50%;
}

.form-header-info {
  display: flex;
  flex-direction: column;
}

.form-header-info > span {
  color: var(--dred);  
  font-weight: 300;
  font-size: 1.3rem;
}

.form-header-info > h2 {
  font-size: 2.3rem;
  font-weight: 500;
}

.form-header-info > p {
  font-weight: 300;
  font-size: 14px;
  color: rgb(119, 119, 119);
  line-height: 30px;
}

.two-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.col-2-desgine {
  width: 50% !important;
}

.field-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
}

.field-container > input {
  width: 100%;
  padding: 20px 20px 20px 20px;
  border-radius: 3px;
  border: 1px solid #ddd !important;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #F6F7F9;
  font-size: 1rem;
}

.textarea-desgine {
  width: 100%;
  margin-top: 15px;
  padding: 15px 20px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #ddd !important;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  border-width: 0px 0px 0px 0px;
  background-color: #F6F7F9;
  font-size: 1rem;
}