footer {
  background-color: var(--xgrey);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-content-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
}

.footer-content-info > ul {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  list-style-type: none;
  justify-content: space-between;
}

.footer-content-info > ul > li {
  display: flex;
  width: 20%;
  flex-direction: column;
  padding: 0 20px;
}

.company-info {
  max-width: 500px;
  color: #fff;
  width: 26% !important;
  display: flex;
  flex-direction: column;
}

.company-info > a {
  margin-top: 20px;
  width: fit-content;
}

.footer-content-info > ul > li > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: 20px;
}

.footer-content-info > ul > li > ul > li {
  padding-bottom: 10px;
}

.footer-content-info > ul > li > ul > li > a{
  color: rgb(163, 163, 163);
  text-decoration: none;
  font-weight: 300;
  font-size: 15px;
  transition: .3s;
}

.footer-content-info > ul > li > ul > li > a:hover {
  color: #fff;
}

.footer-copyright-holder {
  background-color: var(--dgrey);
  width: 100%;
  color: #fff;
  display: flex;
  padding: 20px 0;
  justify-content: center;
}

.company-info > p {
  color: rgb(119, 119, 119);
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.8;
}


.footer-copyright-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-copyright-content > p {
  font-weight: 300;
}

.footer-box-header {
  color: #fff;
}

.footer-box-header > h2 {
  font-weight: 400;
}

.footer-box-header > div {
  width: 50px;
  margin-top: 5px;
  border: 2px solid var(--dred);
}

.contact-info-footer > li {
  color: rgb(119, 119, 119);
}

.contact-info-footer > li > span {
  font-weight: 500;
  color: #fff;
}

.footer-copyright-content > ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
}

.footer-copyright-content > ul > li > a{
  transition: .3s;
  color: #fff;
}

.footer-copyright-content > ul > li > a:hover {
  color: var(--dred);
}