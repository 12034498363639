.item-service {
  display: flex;
  gap: 20px;
  padding: 25px;
  border-radius: 4px;
  margin-top: 20px;
  background-color: #fff;
}

.content-about-single-item > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
}

.content-about-single-item > h2 {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--dred);
}

.icon-hold {
  background-color: rgb(249, 199, 199);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.icon-hold > i {
font-size: 1.4rem; 
color: var(--dred);
}