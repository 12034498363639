.slider-content-holder {
  width: 100%;
  background-image: url("https://themes.envytheme.com/albion/wp-content/uploads/2019/12/banner-bg-shape-2-1-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  justify-content: center;
  border-bottom: 2px solid var(--dred);
}

.slider-info-content {
  display: flex;
  gap: 20px;
  padding: 150px 0;
  padding-bottom: 100px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.slider-content-left-side {
  max-width: 550px;
  z-index: 10;
}

.slider-content-left-side > h1 {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 65px;
  color: var(--xgrey);
}

.slider-content-left-side > p {
  font-weight: 300;
  margin: 30px 0;
  color: rgb(119, 119, 119);
  line-height: 30px;
}

.slider-content-left-side > div {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
}

.text-btn {
  color: var(--xgrey);
  font-weight: 500;
  font-size: 1.1rem;
}

.slider-content-right-side {
  width: 70%;
  right: -40px;

  position: absolute;
}

.slider-content-right-side  > div > img {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.about-section-holder {
  display: flex;
  align-items: center;
  gap: 50px;
  padding-bottom: 40px;
  justify-content: center;
}

.about-section-holder-content{
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 80px 0;
}

.content-for-about-right {
  width: 53%;
  padding: 20px 0;
}

.content-for-about-right > h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.4;
}

.content-for-about-right > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
  margin-top: 20px;
  color: rgb(119, 119, 119);
}

.content-for-about-right > a {
  width: fit-content;
  margin-top: 20px;
}

.image-container {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  border: 5px solid #fff;
  object-fit: cover;
  box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.images-container-left-side {
  width: 45%;
  margin: 20px 0;
  position: relative;;
}

.second-image {
  bottom: -40px;
  right: 50px;
}

.services-section-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lgrey);
  padding: 80px 0;
}

.services-section-holder-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.services-section-holder-content > h2 {
  font-size: 2.3rem;
  font-weight: 500;
  color: var(--xgrey);
}

.services-section-holder-content > p {
  max-width: 800px;
  text-align: center;
  color: rgb(119, 119, 119);
  font-size: 14px;
  line-height: 1.8;
  
}

.services-section-holder-content > ul {
  width: 100%;
  list-style-type: none;
  display: grid;
  margin-top: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.services-section-holder-content > ul > li {
  width: 100%;
  border-radius: 4px;
  transition: .3s;
  background-color: #fff;
  cursor: pointer;
  padding: 30px 25px;
  -webkit-transition: 0.5s;
  transition: 0.3s;
  border-bottom: 3px solid var(--lgrey);
}

.services-section-holder-content > ul > li > h3 {
  font-weight:  500;
  font-size: 23px;
  color: var(--xgrey);
}

.services-section-holder-content > ul > li > div {
  background-color: rgb(249, 199, 199);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.services-section-holder-content > ul > li > div > i {
  color: var(--dred);
  font-size: 1.8rem;
}

.services-section-holder-content > ul > li > p {
  color: rgb(119, 119, 119);
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.8;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;  
  overflow: hidden;
}

.services-section-holder-content > ul > li:hover {
  transform: translateY(-5px);
  border-color: var(--dred);
}

.video-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-info-wrapper {
  position: relative;
  background-image: url("../../../../public/Images/pics/Celebrity\ City\ Hotel\ 1.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 350px -100px;
}

.half-width-video-info {
  padding: 100px 0;
  padding-right: 80px;
  background-color: #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
}

.half-width-video-info > h2 {
  font-size: 2.3rem;
  font-weight: 500;
  color: var(--xgrey);
}

.half-width-video-info > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
  margin-top: 20px;
  color: rgb(119, 119, 119);
}

.half-width-video-info > a {
  width: fit-content;
  margin-top: 20px;
}

.play-button-animated {
  top: 45%;
  bottom: 50%;
  left: 47%;
  right: 50%;
  background-color: var(--dred);
  color: #fff;
  width: 80px;
  transition: .3s;
  cursor: pointer;
  height: 80px;
  border-radius: 50%;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.play-button-animated:hover {
  transform: scale(.9);
}


.fix-title-default {
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: none !important;
  margin: none !important;
  height: 100% !important;
}

.three-box-container {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--dred);
  justify-content: center;
}

.three-box-content {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.three-box-content > ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  list-style-type: none;
}


.three-box-content > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 40px 25px;
  justify-content: center;
}

.three-box-content > ul > li > h2 {
  font-weight: 500;
  color: var(--dred);
  margin-bottom: 10px;
}

.image-container-top{
  border: 2px solid var(--dred);
  padding: 20px;
  width: 90px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 50%;
}

.image-container-top > img {
  width: 100%;
  object-fit: contain;
}

.three-box-content > ul > li > p {
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  line-height: 1.8;
  color: var(--Xgrey);
}

.three-box-content > h2, .Product-View-wrapper > h2 {
  font-weight: 400;
  font-size: 2rem;
  white-space: nowrap;
}

.three-box-content > p {
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 14px;
  color: var(--xgrey);
  text-align: center;
  max-width: 900px;
}


.Product-View-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ddd;
  padding-bottom: 40px;
}

.Product-View-wrapper {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.Product-View-wrapper > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.9;
  margin-bottom: 20px;
  margin-top: 10px;
  max-width: 800px;
  color: var(--xgrey);
}

.Product-View-wrapper > ul {
  width: 100%;
  display: grid;
  margin-top: 50px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  list-style-type: none;
}

.Product-View-wrapper > ul > li {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px; 
}

.Product-View-wrapper > ul > li:nth-child(1) {
  background-image: url(https://www.orionstar.com/res/orics/down/ow001_20220714_bb9745c4e09fada85dba391cd741b3a5.png);
  background-size: 100px;
  background-position: 400px; 

}

.Product-View-wrapper > ul > li:nth-child(2) {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-size: 150px;
  background-position: 400px; 
  background-image: url(https://www.orionstar.com/res/orics/down/ow001_20220715_3ba8af07b31e93cbf446cbf3e2d754d8.png);
}



.Product-View-wrapper > ul > li > div {
  padding: 40px 40px;
  text-align: left;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.799);
}

.Product-View-wrapper > ul > li:nth-child(1) > div, .Product-View-wrapper > ul > li:nth-child(3) > div {
  padding-bottom: 50px !important;
}

.Product-View-wrapper > ul > li > div > h2 {
  font-weight: 500;
  font-size: 1.8rem;
}

.Product-View-wrapper > ul > li > div > span {
  font-weight: 300;
  font-size: 14px;
}

.Product-View-wrapper > ul > li > div > p {
  font-weight: 300;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.8;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;  
  overflow: hidden;
}

.Product-View-wrapper > ul > li > div > a {
  border: 1px solid;
  padding: 10px 15px;
}

.stats-robot-item {
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.stats-robot-item > li {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  width: 33.3%;
}


.stats-robot-item > li > span {
  font-weight: 600;
  font-size: 2.5rem;
  color: var(--dred);
}