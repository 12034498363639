:root {
  --red: #ff151f;
  --dred: #bb1b1f;
  --xgrey: #11191e;
  --dgrey: #2c353b;
  --mgrey: #434d54;
  --lgrey: #e7edf1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

.content-sizing {
  max-width: 1300px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: var(--dred);
}

.general-btn {
  background-color: var(--dred);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  transition: .3s;
  border: none;
  gap: 13px;
}

.general-btn-for-btn {
  font-size: 1.1rem;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 10px;
}

.general-btn:hover {
  background-color: #a7181b;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.general-btn:active {
  transform: scale(.95);
}

.general-btn > i {
  font-size: 1.2rem;
}

.primary-color {
  color: var(--dred);
}