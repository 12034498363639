.header-top-information {
  width: 100%;
  background-color: var(--xgrey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lgrey);
}

.header-top-content {
  width: 100%;
  display: flex;
  font-size: .9rem;
  font-weight: 300;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
}

.header-top-content > ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 30px;
}

.header-top-content > ul > li {
  gap: 10px;
  display: flex;
}

header {
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.header-content {
  display: flex;
  padding: 7px 0;
  justify-content: space-between;
  align-items: center;
}

.header-content > nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.header-menu {
  display: flex;
  align-content: center;
  gap: 30px;
  list-style-type: none;
}

.header-menu > li {
  display: flex;
  gap: 10px;
}



.header-menu > li > a{
  font-weight: 500;
  text-decoration: none;
  display: flex;
  gap: 5px;
  color: var(--xgrey);
}

.header-menu > li > a > span {
  color: var(--dred) !important;
  opacity: 5;
}

.drop-down-menu-container {
  position: relative;
  cursor: pointer;
}

.drop-down-menu-products {
  position: absolute;
  width: 450px;
  right: 0;
  top: 0;
  margin-top: 10px;
  display: none;
  padding: 20px;
  padding-top: 40px;
}

.drop-down-menu-products > ul {
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
}

.drop-down-menu-products > ul > li {
  width: 50%;
  display: flex;
  transition: .3s;
  padding: 20px;
  border-left: 1px solid rgb(238, 238, 238);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-down-menu-products > ul > li:hover {
  background-color: rgb(238, 238, 238);
}

.drop-down-menu-products > ul > li > span {
  font-weight: 300;
  font-size: 14px;
}

.drop-down-menu-products > ul > li > h3 {
  margin-bottom: 0;
  font-size: 1rem;
}

.drop-down-menu-products > ul > li > img {
  margin-bottom: 10px;
}

.drop-down-menu-container:hover .drop-down-menu-products {
  display: block;
}

.pure-link {
  text-decoration: none;
  color: #fff;
}

.pure-link:hover {
  color: #fff;
}